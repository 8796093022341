import React from 'react'
import { Link } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import RichTextRenderer from '~/components/ContentfulRichTextRender'
import MediaBlockImageLeft from '~/components/MediaBlockImageLeft'

const MediaBlockImageRight = ({ data, hasMarginY }) => {
  const { title, content, image, callToAction } = { ...data }
  return (
    <div className={`container-fluid ${hasMarginY ? 'my-2' : 'my-0'}`}>
      <div className="row row-cols-1 row-cols-sm-2 media-row bg-blue-light-2">
        <>
          <div
            className="order-1
                col p-4 p-lg-5 d-flex flex-column align-content-center justify-content-center h-100"
          >
            <span className="fs-48 font-opensans-light txt-gray-900 mb-3">{title}</span>
            <span className="fs-22 font-opensans-regular txt-gray-900 mb-4 overflow-hidden">
              <RichTextRenderer richText={content} />
            </span>
            {callToAction !== null && (
              <Link
                className="d-flex align-items-center justify-content-center fontSFPro-bold fs-18 txt-gray-100 bg-primary home-btn"
                to={callToAction.slug}
              >
                {callToAction.title}
              </Link>
            )}
          </div>

          {image !== null && (
            <div
              className="order-2 col order-sm-1 px-0"
              style={{
                backgroundImage: `url(${getSrc(image)})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            />
          )}
        </>
      </div>
    </div>
  )
}
MediaBlockImageLeft.defaultProps = {
  hasMarginY: true
}
export default MediaBlockImageRight
