import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { Link } from 'gatsby'
import RichTextRenderer from '~/components/ContentfulRichTextRender'
import MediaBlockImageLeft from '~/components/MediaBlockImageLeft'
import MediaBlockImageRight from '~/components/MediaBlockImageRight'
import ContenfulImageOverlayText from '~/components/ContenfulImageOverlayText'

const BecomeAmbassador = ({ data }) => {
  const { blocks, title } = { ...data.contentfulNewPage }

  const TextBlock = data => {
    const { title, content, ctaButton } = { ...data.data }

    return (
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 col-md-9" style={{ paddingLeft: '3.5rem' }}>
            {title && <div className="OSL36 darkFont mb-4">{title}</div>}

            <div className="mediaBlockContent">
              <RichTextRenderer richText={content} />
            </div>
            {ctaButton && (
              <div className="mt-5 mediaBlockCTA" style={{ width: '40%' }}>
                <Link className="d-flex align-items-center justify-content-center w-100" to={ctaButton.slug}>
                  {ctaButton.title}
                </Link>
              </div>
            )}
          </div>
          <div className="col-12 col-md-3" />
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <Metadata title={title} />
      {blocks.map((block, index) => {
        if (block.__typename === 'ContentfulMediaBlock') {
          if (!block.imagePosition) {
            //Image on left
            return <MediaBlockImageLeft data={block} key={index + block.__typename} hasMarginY={false} />
          }
          if (block.imagePosition) {
            //Image on Right
            return <MediaBlockImageRight data={block} key={index + block.__typename} hasMarginY={false} />
          }
        }
        if (block.__typename === 'ContentfulImageWithOverlayText') {
          return <ContenfulImageOverlayText key={index} order={index} data={block} />
        }
        if (block.__typename === 'ContentfulTextBlock') return <TextBlock key={index} data={block} />
      })}
      <div className="mb-8" />
    </Layout>
  )
}

export default BecomeAmbassador

export const query = graphql`
  query NewPageBecomeAmbassadorQuery {
    contentfulNewPage(identifier: { eq: "become-ambassador" }) {
      title
      blocks {
        ... on ContentfulImageWithOverlayText {
          __typename
          id
          fontColor
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          subheading
          textBlockWidth
          buttonTitle
          buttonLink
        }
        ... on ContentfulVideoBlock {
          __typename
          title
          youtubeId
        }
        ... on ContentfulTextBlock {
          __typename
          title
          content {
            raw
          }
          ctaButton {
            title
            slug
          }
        }
        ... on ContentfulMediaBlock {
          __typename
          id
          title
          showTitle
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          imageAltText
          imagePosition
          showBackground
          backgroundColor
          callToAction {
            title
            slug
          }
          callToActionRight
          content {
            raw
          }
        }
      }
    }
  }
`
